<template>
  <basic-container>
    <avue-skeleton :loading="waiting"
                   avatar
                   :rows="8">
      <avue-title style="margin-bottom: 20px; "
                  :styles="{fontSize: '20px'}"
                  :value="process.name"></avue-title>
      <el-card shadow="never"
               style="margin-top: 20px">
        <!-- 自定义表单区域 -->
        <avue-form v-if="option && ((option.column && option.column.length > 0) || (option.group && option.group.length > 0))"
                   v-model="form"
                   ref="form"
                   :option="option"
                   :defaults.sync="defaults"
                   @submit="handleSubmit"
                   @error="loading = false"
                   :upload-preview="handleUploadPreview">
        </avue-form>
        <!-- 自定义表单区域 -->
      </el-card>

      <el-card shadow="never"
               style="margin-top: 20px"
               v-if="showExamForm">
        <wf-examine-form ref="examineForm"
                         :process="process"
                         @user-select="handleUserSelect"></wf-examine-form>
      </el-card>
      <div style="height: 120px;"></div>
      <el-row class="foot-item avue-affix"
              :style="{width: layoutType == 'functional'?'100%':isCollapse? 'calc(100% - 68px)': 'calc(100% - 220px)' }"
              id="avue-view">
        <el-button type="primary"
                   size="medium"
                   v-loading="loading"
                   @click="handleSubmit">发起</el-button>
        <el-button v-if="permission.wf_process_draft"
                   type="success"
                   size="medium"
                   v-loading="loading"
                   @click="handleDraft(process.id, process.formKey, form)">存为草稿</el-button>
      </el-row>
    </avue-skeleton>

    <!-- 人员选择弹窗 -->
    <wf-user-select ref="user-select"
                    :check-type="checkType"
                    :default-checked="defaultChecked"
                    @onConfirm="handleUserSelectConfirm"></wf-user-select>
  </basic-container>
</template>

<script>
import WfExamineForm from '@/views/plugin/workflow/process/components/examForm.vue'
import WfUserSelect from '@/views/plugin/workflow/process/components/user-select'

import exForm from '@/views/plugin/workflow/mixins/ex-form'
import draft from '@/views/plugin/workflow/mixins/draft'
import {mapGetters} from "vuex";

export default {
  components: {
    WfUserSelect, WfExamineForm
  },
  mixins: [exForm, draft],
  watch: {
    '$route.query.p': {
      handler(val) {
        if (val) {
          console.log("val",val)
          const param = JSON.parse(Buffer.from(val, 'base64').toString())
          const { processId } = param
          if (processId) this.getForm(processId)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["isCollapse","layoutType"]),
    showExamForm() {
      const { hideComment, hideCopy, hideExamine } = this.process
      return !hideComment || !hideCopy || !hideExamine
    }
  },
  data() {
    return {
      defaults: {},
      form: {},
      option: {
        menuBtn: false,
        column: [
          {
            type: 'input',
            label: '创建人',
            span: 12,
            display: true,
            prop: 'creator',
            value: '${this.$store.getters.userInfo.nick_name}',
            readonly: true
          },
          {
            type: 'input',
            label: '创建部门',
            span: 12,
            display: true,
            prop: 'createDept',
            value: '${this.$store.getters.userInfo.dept_name}',
            readonly: true
          },
          {
            type: 'datetimerange',
            label: '请假时间',
            span: 12,
            display: true,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            prop: 'datetime',
            required: true,
            rules: [
              {
                required: true,
                message: '开始时间必须填写'
              }
            ],
            change: ({ value }) => {
              if (!value || value.length == 0) {
                this.$set(this.form, 'days', undefined)
              } else {
                const d1 = Date.parse(value[0])
                const d2 = Date.parse(value[1])
                const day = (d2 - d1) / (1 * 24 * 60 * 60 * 1000)
                this.$set(this.form, 'days', Number(day.toFixed(2)))
              }
            }
          },
          {
            type: 'number',
            label: '请假天数',
            span: 12,
            display: true,
            prop: 'days',
            required: true,
            rules: [
              {
                required: true,
                message: '请假天数必须填写'
              }
            ],
            controls: true,
            controlsPosition: 'right',
            change: ({ value }) => {
              this.$set(this.form, 'reason', '请假' + value + '天')
            }
          },
          {
            type: 'textarea',
            label: '请假理由',
            span: 24,
            display: true,
            prop: 'reason',
            required: true,
            rules: [
              {
                required: true,
                message: '请假理由必须填写'
              }
            ]
          },
          {
            label: '附件',
            type: 'upload',
            propsHttp: {
              res: 'data',
              url: 'link',
              name: 'originalName'
            },
            action: '/api/sinoma-resource/oss/endpoint/put-file',
            display: true,
            span: 24,
            showFileList: true,
            multiple: true,
            limit: 10,
            prop: 'attachment'
          },
        ]
      },
      process: {},
      loading: false
    }
  },
  methods: {
    getForm(processId) {
      this.getStartForm(processId).then(res => {
        let { process, startForm } = res
        this.form.processId = process.id
        const option = this.option
        const { column, group } = option
        const groupArr = []
        const columnArr = this.filterAvueColumn(column, startForm, true).column
        if (group && group.length > 0) { // 处理group
          group.forEach(gro => {
            gro.column = this.filterAvueColumn(gro.column, startForm, true).column
            if (gro.column.length > 0) groupArr.push(gro)
          })
        }

        option.column = columnArr
        option.group = groupArr
        this.option = option

        if (this.permission.wf_process_draft) {
          // 查询是否有草稿箱
          this.initDraft(process.id).then(data => {
            this.$confirm('是否恢复之前保存的草稿？', '提示', {
            }).then(() => {
              this.form = JSON.parse(data)
            }).catch(() => {
            })
          })
        }
        this.waiting = false
      })
    },
    handleSubmit() {
          this.handleStartProcess5(true).then(done => {
            this.$message.success("发起成功")
            this.handleCloseTag('/plugin/workflow/process/send')
            done()
          }).catch(() => {
            this.loading = false
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.foot-item {
  position: fixed;
  bottom: 0;
  //margin-left: -20px;
   right: 0;
  z-index: 101;
  height: 66px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
